import './style.scss';

export const Header = ({
  week,
}: {
  week?: number | undefined,
}) => {
  return (
    <div className="header-component">
      <h1>
        Hva er det til lunsj?<span title="I Kanalpiren, Laberget 28, 4020 Stavanger">*</span>
      </h1>
      {week && (
        <h2>Uke {week}</h2>
      )}
    </div>
  );
};
