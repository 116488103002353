import { ReactNode, useMemo } from 'react';
import { dinnerStrings } from '../../constants';
import { Weekday } from '../../types/weekday';
import { Dish } from '../../types/dish';
import './style.scss';

const DayCard = ({
  day,
  dishes,
  today = false,
}: {
  day: Weekday,
  dishes: Dish[],
  /** Whether to show day as the current day */
  today?: boolean,
}) => {
  dishes.sort((a, b) => b.type.localeCompare(a.type));


  const mainDish = useMemo(() => {
    const main = dishes.find(d => dinnerStrings.some(s => s === d.type.toLowerCase()));
    if (main) return main;
    if (dishes.length <= 0) return undefined;
    return dishes.length > 0 && dishes[0];
  }, [dishes]);

  let img: ReactNode | null = null;
  if (mainDish) {
    const url = 'https://images.hvaerdettillunsj.no/';
    const dishName = mainDish.dish.replace(/ /g, '_').replace(/[^a-zA-Z0-9æøåé_]/g, '');
    const src = `${url}${dishName}.png`;
    img = <img className="day-image" src={src} alt="Bilde av dagens hovedrett" />;
  }

  return (
    <div className={`day-card-wrapper${today ? ' today' : ''}`}>
      <div className="day-image-wrapper">
        {img}
      </div>
      <div className="content-wrapper">
        <div className="day-card-header">
          {day}
        </div>
        <div className="day-card-body">
          <ul className="meal-list">
            {dishes.map(u => (
              <li className="meal-item-wrapper" key={u.type}>
                <div className="meal-header">{u.type}</div>
                <div className={`meal-body${u.favourite ? ' fav' : ''}`}>{u.dish}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { DayCard };
