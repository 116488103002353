export const dinnerStrings: string[] = [
  'varmrett',
  'hovedrett',
  'varmmat',
] as const;

export const soupStrings: string[] = [
  'suppe',
] as const;

export const favourites: string[] = [
] as const;
