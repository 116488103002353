/** Week days in DateTime.getDay() order */
export const weekdays = [
  'Søndag',
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
] as const;

/** Name of day */
export type Weekday = typeof weekdays[number];
