import { dinnerStrings, favourites, soupStrings } from '../constants';
import { Dish } from '../types/dish';

export const checkType = (dish: string, keywords: readonly string[]) => {
  for (let i = 0; i < keywords.length; i++) {
    if (dish.toLowerCase().startsWith(keywords[i].toLowerCase())) {
      return true;
    }
  }
  return false;
};

export const untangle = (ds: string[]) => {
  const dishes: Dish[] = [];
  for (let i = 0; i < ds.length; i++) {
    const d = ds[i];
    const food = d.replace(/^dagens/i, '')
      .replace(/:+/i, ' ')
      .replace(/^ +/, '');

    // Set to first word by default
    let type = food.replace(/ .*/, '');
    if (checkType(food, dinnerStrings)) {
      type = 'Varmrett';
    } else if (checkType(food, soupStrings)) {
      type = 'Suppe';
    }

    const dish = food.replace(/^.*? /, '').replace(/^ +/, '');

    if (dish) {
      const favourite = favourites.some(f => dish.toLowerCase().includes(f.toLowerCase()));

      dishes.push({ type, dish, favourite });
    }
  }
  return dishes;
};
