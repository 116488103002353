import axios from 'axios';
import { useEffect, useState } from 'react';
import { DayCard } from '../DayCard';
import { untangle } from '../../helpers/data';
import { ApiData } from '../../types/apiData';
import { Weekday, weekdays } from '../../types/weekday';
import { Dish } from '../../types/dish';
import { Header } from '../Header';
import './style.scss';

type FixedData = {
  day: Weekday,
  dishes: Dish[],
}

/** Get the current day */
const getToday = () => (
  weekdays[(new Date()).getDay()]
);

/** Number of milliseconds in an hour */
const hourInMillis = 3600 * 1000;

export const DayList = () => {
  const [data, setData] = useState<FixedData[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [week, setWeek] = useState<number>();
  const [today, setToday] = useState<Weekday>(getToday());

  useEffect(() => {
    const ref = setInterval(() => setToday(getToday()), hourInMillis);
    return () => clearInterval(ref);
  }, []);

  useEffect(() => {
    axios.get<ApiData>('https://i74qu6dp3m.execute-api.us-east-2.amazonaws.com/').then(
      result => {
        const data = result.data.days.map((d) => ({
          day: d.day,
          dishes: untangle(d.dishes),
        }));
        setIsLoaded(true);
        setData(data);
        setWeek(result.data.weekNumber);
      },
      error => {
        setIsLoaded(true);
        setError(error);
      },
    );
  }, []);

  return (
    <div>
      <Header week={week} />
      <div className="day-list-component">
        {!isLoaded && (
          'Henter meny...'
        )}
        {error && (
          error.message
        )}
        {data && data.map(d => (
          <DayCard key={d.day} day={d.day} dishes={d.dishes} today={d.day === today} />
        ))}
      </div>
    </div>
  );
};
